import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import { IGalleryImageMetadata } from '../images';

export interface IGalleryQueryNode {
  node: {
    id: string;
    fluid: {
      originalName: string;
      aspectRatio: number;
      src: string;
      srcSet: string;
      sizes: string;
    };
  };
}

export interface IGalleryQuery {
  allImageSharp: {
    edges: IGalleryQueryNode[];
  };
}

export interface IImage extends IGalleryImageMetadata, IGalleryQueryNode {}

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: max-content;
  grid-gap: 1.5rem;
  margin: 0 auto;
  img {
    object-fit: cover;
  }
`;

const ImageGrid = ({ imagesFilter }: { imagesFilter: IGalleryImageMetadata[] }) => {
  return (
    <Gallery className="gallery">
      <StaticQuery
        query={graphql`
          query GalleryImages {
            allImageSharp {
              edges {
                node {
                  id
                  fluid {
                    ...GatsbyImageSharpFluid
                    originalName
                  }
                }
              }
            }
          }
        `}
      >
        {(data: IGalleryQuery) => {
          return data.allImageSharp.edges
            .reduce((arr: IImage[], image: IGalleryQueryNode) => {
              const i = imagesFilter.map(img => img.url).indexOf(image.node.fluid.originalName);
              if (i >= 0) {
                arr.push({
                  ...image,
                  ...imagesFilter[i]
                });
              }
              return arr;
            }, [])
            .map((image: IImage) => {
              // node.fluid.originalName
              return <Img key={image.node.id} alt={image.alt} fluid={image.node.fluid} />;
            });
        }}
      </StaticQuery>
    </Gallery>
  );
};

export default ImageGrid;
