import React from 'react';
import ImageGrid from '../components/ImageGrid';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { homePage } from '../images';

const HomePage = () => (
  <Layout>
    <SEO title="Animation" description="Sara Melo's home page." />
    <div className="row">
      <div className="block-content">
        <ImageGrid imagesFilter={homePage} />
      </div>
    </div>
  </Layout>
);

export default HomePage;
