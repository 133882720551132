export interface IGalleryImageMetadata {
  alt: string;
  url: string;
}

export const homePage: IGalleryImageMetadata[] = [
  {
    alt: 'Needle felted pig with a weaved background.',
    url: 'felt-pig.jpg'
  },
  {
    alt: 'Weaving of mountains against a blue sky with a couple of clouds.',
    url: 'weaved-mountain.jpg'
  },
  {
    alt: 'Weaving of mountains against a blue sky with a couple of clouds on a white wooden frame.',
    url: 'weaved-frame.jpg'
  },
  {
    alt: 'Close up photo of my art studio at college containing a number of drawings and paintings.',
    url: 'studio.jpg'
  },
  {
    alt: 'Wall painting of a bamboo forest for an art installation.',
    url: 'exhibition-3.jpg'
  },
  {
    alt: 'Three types of handmade paper, one with embedded flowers and the other two with fabric fibers.',
    url: 'paper.jpg'
  }
];

export const profile: IGalleryImageMetadata[] = [
  {
    alt: 'Exhibition setup containing frames and screen playing my animation for Chinese Zodiac.',
    url: 'exhibition-1.jpg'
  },
  {
    alt: 'Exhibition setup containing frames and screen playing my animation for Omosubi Kororin.',
    url: 'exhibition-2.jpg'
  },
  {
    alt: 'Exhibition setup containing frames for my exhibition about the story of The Red Ogre that Cried.',
    url: 'exhibition-4.jpg'
  }
];
